<template>
  <div class="contentView paymentDeductBill">
    <div class="centerView">
      <a-form ref="filterFormRef" :model="filterForm" layout="inline">
        <a-form-item name="associatedNumber">
          <a-input v-model:value.trim="filterForm.sourceSn" allowClear size="large" :placeholder="t('paymentDeductBill.filterForm.associatedNumber')" />
        </a-form-item>
        <a-form-item name="paymentType">
          <a-select v-model:value="filterForm.paymentMethod" :placeholder="t('paymentDeductBill.filterForm.paymentType')" size="large" :style="{ width: '188px' }">
            <a-select-option :value="item.value" v-for="item in paymentTypes" :key="item.value">{{ t(item.name) }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item name="type">
          <a-select v-model:value="filterForm.type" :placeholder="t('paymentDeductBill.filterForm.type')" size="large" :style="{ width: '188px' }">
            <a-select-option :value="item.value" v-for="item in typeList" :key="item.value">{{ t(item.name) }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item name="startTime">
          <a-range-picker v-model:value="date" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" :showTime="showTime" :placeholder="[t('beginTime'), t('endTime')]" size="large">
            <template #suffixIcon>
              <img style="width: 15px; height: 15px; display: flex" src="@/assets/images/table/timePickIcon.png" alt="" />
            </template>
          </a-range-picker>
        </a-form-item>
        <a-form-item>
          <a-space :size="16">
            <a-button type="primary" size="large" @click="search">{{ t('search') }}</a-button>
            <div class="form-item-button gray" @click="reset">{{ t('reset') }}</div>
          </a-space>
        </a-form-item>
      </a-form>
    </div>

    <div class="centerView">
      <div class="tabs-wrap">
        <a-tabs v-model:activeKey="activeKey" size="small">
          <a-tab-pane v-for="item in tabsPaneList" :key="item.key" :tab="`${t(item.tab)}(${item.num})`"></a-tab-pane>
        </a-tabs>
        <a-button type="primary" class="download" :disabled="!isDisableExportBtn" @click="exportFn">{{ t('paymentDeductBill.table.download') }}</a-button>
      </div>

      <a-table :dataSource="dataSource" :columns="columns" :loading="tableLoading" :row-selection="rowSelection" :pagination="false" row-key="id" size="small" class="ant-table-striped" :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" :scroll="{ x: 1300 }">
        <template #sourceSn="{ text }">
          <span>{{ text || '-' }}</span>
        </template>
        <template #categoryValue="{ text }"> {{ text }} </template>
        <template #amount="{ text, record }">
          <div :style="{ color: record?.typeValue === '扣款' ? 'red' : '' }">{{ text }}</div>
        </template>
        <template #paymentMethodValue="{ text }"> {{ text }} </template>
        <template #remark="{ text }">
          {{ text }}
        </template>
        <template #confirmVoucherList="{ text }">
          <div class="confirm-voucher-box">
            <a-popover v-for="(item, index) in text.slice(0, 5)" :key="index">
              <template #content> <img class="confirm-voucher-hover-img" :src="formatImg(item, 100, 150, 100, 150)" /> </template>
              <img class="confirm-voucher-img" :src="formatImg(item, 40, 60, 40, 60)" />
            </a-popover>
          </div>
        </template>
      </a-table>

      <TableSkeleton v-if="tableLoading" />

      <pagination mt lable="" @change="onPageChange" :data="tableResult" @sizeChange="onSizeChange"></pagination>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  import { ref, watch, computed, unref, onMounted } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import { message } from 'ant-design-vue'
  import { formatImg } from '@/utils/util'
  import { useI18n } from 'vue-i18n'
  import moment from 'moment'
  import { transformColumnsI18n, filterInitialColumnsFn } from '@/language'
  import pagination from '@/components/pagination'
  import TableSkeleton from '@/views/supplier/components/TableSkeleton'
  import ImgGallery from '@/views/supplier/components/ImgGallery'
  import OperationLogModal from '@/views/supplier/components/OperationLogModal'
  import { initialColumns, initialColumns1 } from './columns'
  import { getTableApi, getPaymentMethodApi, getDeductTypeApi, getTabSummaryApi, exportDeductionDetailApi } from '@/api/supplier/financialManagement/paymentDeductBill'

  export default {
    name: 'paymentDeductBill',
    components: {
      pagination,
      TableSkeleton,
      ImgGallery,
      OperationLogModal,
    },
    setup() {
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const { t, locale } = useI18n()
      // tabs对应的map数据
      const tabsKeyMap = {
        0: '待处理',
        10: '已完成',
        90: '已驳回',
      }
      // tab列表数据
      const tabsPaneList = ref([])
      /*  */
      const activeKey = ref(0)
      /**
       * 获取tab上的数量汇总
       */
      function getOrderManageTotalsDataFn() {
        const userInfo = computed(() => store.getters.userInfo)
        const { token = '' } = userInfo.value
        getTabSummaryApi({
          token,
        })
          .then(res => {
            if (res.code === 200) {
              const data = res?.data
              const dataArr = Object.keys(data)
              const tempArr = []
              dataArr.map((item, index) => {
                // 转为贺斌之前写的数据结构
                tempArr.push({
                  tab: tabsKeyMap[item],
                  num: data[item],
                  key: index,
                  index: item,
                })
              })
              tabsPaneList.value = tempArr
            } else {
              message.error(res.message)
            }
          })
          .catch(error => {
            console.error(error)
          })
      }
      // vuex用户信息
      const userInfo = computed(() => store.getters.userInfo)
      const { token = '' } = userInfo.value
      // 初始筛选条件
      const initialFilterForm = {
        token, // 商家token
        sourceSn: null, // 原单编号
        paymentMethod: null, // 支付方式 1 现结 2 账期
        startTime: null, // 开始时间
        endTime: null, // 结束时间
        type: null, // 类型 1 补款 2 扣款
        page: 1, // 页码
        size: 10, // 每页显示数量
        state: 0, //状态 0 待处理 10 已完成 90 已驳回
      }
      // 筛选条件
      const filterForm = ref({ ...initialFilterForm })
      // 日期
      const date = ref([])

      const showTime = {
        hideDisabledOptions: true,
        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
      }
      /* 支付方式 */
      const paymentTypes = ref([])
      /* 扣款类型 */
      const typeList = ref([])
      // 响应式columns数据
      const columns = ref([])
      watch(locale, () => {
        let keys = columns.value.map(({ key }) => key)
        columns.value = transformColumnsI18n(filterInitialColumnsFn(initialColumns, keys))
      })

      const tableLoading = ref(false)
      const dataSource = ref([])
      const tableResult = ref()
      onMounted(async () => {
        try {
          // 获取支付方式
          const res = await getPaymentMethodApi()
          // 获取扣款类型
          const deductTypeRes = await getDeductTypeApi()
          if (res.code === 200) {
            paymentTypes.value = res.data
          } else {
            message.error(res.message)
          }
          if (deductTypeRes.code === 200) {
            typeList.value = deductTypeRes.data
          } else {
            message.error(deductTypeRes.message)
          }
          // 获取初始（待处理）columns
          filterColumns(0)
          getTableData()
        } catch (error) {
          console.error(error)
        }
      })
      /**
       * 获取table数据
       */
      function getTableData() {
        tableLoading.value = true
        // 获取table数据时先获取tab数量
        getOrderManageTotalsDataFn()
        tableResult.value = {}
        dataSource.value = []
        const [startTime = null, endTime = null] = date.value || []
        getTableApi({ ...filterForm.value, startTime, endTime })
          .then(res => {
            tableLoading.value = false
            if (res.code === 200) {
              tableResult.value = res.data
              dataSource.value = tableResult.value.data
              selectedRowList.value = []
              selectedRowKeysList.value = []
            } else {
              // message.error(res.msg)
            }
          })
          .catch(() => {
            tableLoading.value = false
          })
      }
      /**
       * 重置
       */
      function reset() {
        filterForm.value = { ...filterForm.value, ...initialFilterForm }
        date.value = []
        activeKey.value = 0
        getTableData()
      }
      /**
       * 搜索
       */
      function search() {
        filterForm.value.page = 1
        getTableData()
      }
      /**
       * page改变事件
       */
      function onPageChange(e) {
        filterForm.value.page = e
        getTableData()
      }
      /**
       * size改变事件
       */
      function onSizeChange({ pageSize }) {
        filterForm.value.size = pageSize
        filterForm.value.page = 1
        getTableData()
      }
      // 选中的行key列表
      const selectedRowKeysList = ref([])
      // 选中的行数据
      const selectedRowList = ref([])
      const rowSelection = computed(() => {
        return {
          // getCheckboxProps: record => ({
          //   disabled: dataSource.value.some(it => it.attributeId == record.id),
          // }),
          fixed: true,
          selectedRowKeys: unref(selectedRowKeysList),
          onChange: (selectedRowKeys, selectedRows) => {
            selectedRowKeysList.value = selectedRowKeys
            selectedRowList.value = selectedRows
          },
          hideDefaultSelections: true,
        }
      })
      /**
       * 导出补扣款明细按钮是否可用
       */
      const isDisableExportBtn = computed(() => {
        return selectedRowKeysList.value.length !== 0 || !!filterForm.value.sourceSn || !!filterForm.value.paymentMethod || !!filterForm.value.type || !!date.value[0]
      })
      /* 导出功能 */
      function exportFn() {
        const ids = []
        selectedRowList.value.map(item => {
          ids.push(item.id)
        })
        const [startTime = null, endTime = null] = date.value || []
        const idString = ids.join()
        const payload = { ...filterForm.value, ids: idString, startTime, endTime }
        exportDeductionDetailApi(payload)
          .then(res => {
            if (res.code === 200) {
              message.success(t('opSuccess'))
            } else {
              message.error(res.message)
            }
          })
          .catch(() => {})
        selectedRowKeysList.value = []
        selectedRowList.value = []
        getTableData()
      }

      watch(
        () => activeKey.value,
        newValue => {
          filterForm.value.state = tabsPaneList.value.find(it => it.key == newValue).index
          filterColumns(newValue)
          search()
        }
      )
      /**
       * 根据key获取对应columns数据
       * @param {*} newValue 选中tab项
       */
      function filterColumns(newValue) {
        let filterKeys = []
        let tempColumns = []
        if (newValue == 0) {
          filterKeys = ['compensationDeductionSn', 'categoryValue', 'paymentMethodValue', 'amount', 'sourceOrderTypeValue', 'sourceSn', 'remark', 'typeValue', 'createAt']
          tempColumns = initialColumns
        }
        if (newValue == 1) {
          filterKeys = ['compensationDeductionSn', 'categoryValue', 'paymentMethodValue', 'amount', 'sourceOrderTypeValue', 'sourceSn', 'remark', 'confirmVoucherList', 'typeValue', 'createAt']
          tempColumns = initialColumns1
        }
        if (newValue == 2) {
          filterKeys = ['compensationDeductionSn', 'categoryValue', 'paymentMethodValue', 'amount', 'sourceOrderTypeValue', 'sourceSn', 'remark', 'typeValue', 'createAt']
          tempColumns = initialColumns
        }
        columns.value = transformColumnsI18n(filterInitialColumnsFn(tempColumns, filterKeys))
      }

      function overDateFn({ dissentReceiptAt, finishAt }) {
        if (!dissentReceiptAt) return 0
        dissentReceiptAt = moment(dissentReceiptAt).format('YYYY-MM-DD')
        const endDate = activeKey.value == '2' ? Date.now() : finishAt || Date.now()
        // const endDate = activeKey.value == '2' ? moment().format('YYYY-MM-DD') : moment(finishAt).format('YYYY-MM-DD');
        return moment(moment(endDate).format('YYYY-MM-DD')).diff(dissentReceiptAt, 'day')
      }

      const operationLogModalVisible = ref(false)

      return {
        t,
        filterForm,
        paymentTypes,
        typeList,
        date,
        showTime,
        activeKey,
        tabsPaneList,
        columns,
        dataSource,
        tableResult,
        tableLoading,
        search,
        reset,
        onPageChange,
        onSizeChange,
        rowSelection,
        selectedRowList,
        exportFn,
        overDateFn,
        operationLogModalVisible,
        formatImg,
        isDisableExportBtn,
      }
    },
  }
</script>

<style lang="less">
  .paymentDeductBill {
    .ant-table-wrapper {
      margin-bottom: 10px;
    }
    .tabs-wrap {
      position: relative;
      .download {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .ant-table-striped :deep(.table-striped) td {
      background-color: #fafafa;
    }
    .over-date {
      display: inline-block;
      height: 20px;
      line-height: 20px;
      padding: 0 5px;
      color: #fff;
      font-size: 12px;
      text-align: center;
      border-radius: 10px;
      background-color: #f53838;
      white-space: nowrap;
    }
    .confirm-voucher-img {
      width: 40px;
      height: 60px;
    }
    .confirm-voucher-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .confirm-voucher-hover-img {
      width: 100px;
      height: 150px;
    }
  }
</style>
<style lang="less">
  // @import url('/src/assets/supplier/css/custom.less');
</style>
