/**
 * 待处理/已驳回columns
 */
export const initialColumns = [
  {
    title: 'paymentDeductBill.table.number', // 补扣款单号
    dataIndex: 'compensationDeductionSn',
    key: 'compensationDeductionSn',
    width: 120,
    slots: { customRender: 'compensationDeductionSn' },
  },
  {
    title: 'paymentDeductBill.table.classify', // 补扣款分类
    dataIndex: 'categoryValue',
    key: 'categoryValue',
    width: 100,
    slots: { customRender: 'categoryValue' },
  },
  {
    title: 'paymentDeductBill.table.paymentType', // 结算方式
    dataIndex: 'paymentMethodValue',
    key: 'paymentMethodValue',
    width: 80,
    slots: { customRender: 'paymentMethodValue' },
  },
  {
    title: 'paymentDeductBill.table.money', // 补扣款金额
    dataIndex: 'amount',
    key: 'amount',
    width: 100,
    slots: { customRender: 'amount' },
  },
  {
    title: 'paymentDeductBill.table.originalType', // 原单类型
    dataIndex: 'sourceOrderTypeValue',
    key: 'sourceOrderTypeValue',
    width: 100,
    slots: { customRender: 'sourceOrderTypeValue' },
  },
  {
    title: 'paymentDeductBill.table.associatedNumber', // 关联单号
    dataIndex: 'sourceSn',
    key: 'sourceSn',
    width: 100,
    slots: { customRender: 'sourceSn' },
  },
  {
    title: 'paymentDeductBill.table.remark', // 备注
    dataIndex: 'remark',
    key: 'remark',
    align: 'center',
    width: 120,
    slots: { customRender: 'remark' },
  },
  {
    title: 'paymentDeductBill.table.type', // 补扣类型
    dataIndex: 'typeValue',
    key: 'typeValue',
    width: 80,
    slots: { customRender: 'typeValue' },
  },
  {
    title: 'paymentDeductBill.table.applicationTime', // 申请时间
    dataIndex: 'updateAt',
    key: 'updateAt',
    width: 80,
    slots: { customRender: 'updateAt' },
  },
  // {
  //   title: 'paymentDeductBill.table.operator',
  //   dataIndex: 'operator',
  //   key: 'operator',
  //   width: 120,
  //   slots: { customRender: 'operator' },
  // },
  {
    title: 'paymentDeductBill.table.applicationTime',
    dataIndex: 'applicationTime',
    key: 'applicationTime',
    width: 120,
    slots: { customRender: 'applicationTime' },
  },
]
/**
 * 已完成columns
 */
export const initialColumns1 = [
  {
    title: 'paymentDeductBill.table.number', // 补扣款单号
    dataIndex: 'compensationDeductionSn',
    key: 'compensationDeductionSn',
    width: 120,
    slots: { customRender: 'compensationDeductionSn' },
  },
  {
    title: 'paymentDeductBill.table.classify', // 补扣款分类
    dataIndex: 'categoryValue',
    key: 'categoryValue',
    width: 100,
    slots: { customRender: 'categoryValue' },
  },
  {
    title: 'paymentDeductBill.table.paymentType', // 结算方式
    dataIndex: 'paymentMethodValue',
    key: 'paymentMethodValue',
    width: 80,
    slots: { customRender: 'paymentMethodValue' },
  },
  {
    title: 'paymentDeductBill.table.money', // 补扣款金额
    dataIndex: 'amount',
    key: 'amount',
    width: 100,
    slots: { customRender: 'amount' },
  },
  {
    title: 'paymentDeductBill.table.originalType', // 原单类型
    dataIndex: 'sourceOrderTypeValue',
    key: 'sourceOrderTypeValue',
    width: 100,
    slots: { customRender: 'sourceOrderTypeValue' },
  },
  {
    title: 'paymentDeductBill.table.associatedNumber', // 关联单号
    dataIndex: 'sourceSn',
    key: 'sourceSn',
    width: 100,
    slots: { customRender: 'sourceSn' },
  },
  {
    title: 'paymentDeductBill.table.remark', // 备注
    dataIndex: 'remark',
    key: 'remark',
    align: 'center',
    width: 120,
    slots: { customRender: 'remark' },
  },
  {
    title: 'paymentDeductBill.table.confirmationCertificate', // 确认凭证
    dataIndex: 'confirmVoucherList',
    key: 'confirmVoucherList',
    align: 'center',
    width: 120,
    slots: { customRender: 'confirmVoucherList' },
  },
  {
    title: 'paymentDeductBill.table.type', // 补扣类型
    dataIndex: 'typeValue',
    key: 'typeValue',
    width: 80,
    slots: { customRender: 'typeValue' },
  },
  {
    title: 'paymentDeductBill.table.completionTime', // 完成时间
    dataIndex: 'createAt',
    key: 'createAt',
    width: 80,
    slots: { customRender: 'createAt' },
  },
]
